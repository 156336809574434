import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import SectionHeading from '../components/SectionHeading'
import { Section, InnerMedium, InnerSmall } from '../components/Sections'
import Form from '../components/pageSections/Form'
import color from '../constants/colors'

const InnerStyledGray = styled(InnerSmall)`
  background-color: ${color.lightBlue};
  padding: 30px;
`

const FormWrapper = styled.div`
  background-color: ${color.silverBg};
  margin-bottom: 50px;
  padding: 50px 15px 80px;
  text-align: center;
`

const Paragraph = styled.p`
  font-size: 1.8rem;
  margin-bottom: 80px;
`

const CoursePrice = styled.p`
  font-size: 2.1rem;
`

const PaymentPage = ({ location: { pathname } }) => (
  <Layout>
    <SEO
      title="お支払ページ（リリースキャンペーン）　| ITエンジニア専用のオンライン英会話スクール・Moba Pro IT English（モバプロ）"
      description="申込済みの生徒様向けのお支払ページです。"
      pathName={pathname}
    />
    <SectionHeading withoutAnimation>
      お支払ページ
    </SectionHeading>
    <Section>
      <InnerStyledGray>
        <p>すでにお申込みを済まされた生徒様向けの受講料金のお支払ページです。</p>
        <p>
          まだお申込みをされていない場合は、お問い合わせフォーム、またはすでにやりとりをされている運営のメールアドレス宛にお申し込み希望の旨をお伝え下さい。
          運営よりお申し込み方法のご案内をいたします。
        </p>
      </InnerStyledGray>
    </Section>
    <Section>
      <InnerMedium>
        <FormWrapper>
          <h2>コース名: 12回コース（リリースキャンペーン価格）</h2>
          <CoursePrice>お支払い料金総額（税込）:　105,600円</CoursePrice>
          <Paragraph>お支払い方法:　クレジットカードによる一括払い</Paragraph>
          <Form type="payment-course-12-lessons-campaign" />
        </FormWrapper>
      </InnerMedium>
    </Section>
  </Layout>
)

PaymentPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

PaymentPage.defaultProps = {
  location: null,
}

export default PaymentPage
